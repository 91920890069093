import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import Layout from "../components/new/Layout";
import Spacer from "../components/new/Spacer";
import RichTextBlock from "../components/new/blocks/RichTextBlock";
import ButtonLink from "../components/new/ButtonLink";

const Container = styled.div`
  min-height: calc(90vh - 80px);
`;

const ModernSlaveryActPage = ({ data }) => {
  const content = data.prismic.allModernSlaveryActs.edges[0].node;
  const meta = data.site.siteMetadata;

  if (!content) {
    return null;
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={`Modern Slavery Act`}
        titleTemplate={`%s | IPG - DXTRA`}
        meta={[
          {
            name: `description`,
            content: meta.description,
          },
          {
            property: `og:title`,
            content: `Modern Slavery Act | IPG - DXTRA`,
          },
          {
            property: `og:description`,
            content: meta.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: meta.author,
          },
          {
            name: `twitter:title`,
            content: meta.title,
          },
          {
            name: `twitter:description`,
            content: meta.description,
          },
        ].concat(meta)}
      />
      <Layout navigationTransparent={false}>
        <Container>
          <Grid>
            <Row>
              <Col xs={12} mdOffset={1} md={10} lgOffset={2} lg={8}>
                <Spacer padding={{ xs: "80px 0 40px", m: "180px 0 80px" }}>
                  <RichTextBlock
                    heading={content.page_title}
                    date={content.date}
                    body={content.body_copy}
                  />
                  {content.attachment && (
                    <a
                      href={content.attachment.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click here for our Modern Slavery Act declaration.
                    </a>
                  )}
                </Spacer>
              </Col>
            </Row>
          </Grid>
        </Container>
      </Layout>
    </>
  );
};

ModernSlaveryActPage.propTypes = {
  data: PropTypes.object.isRequired,
};

const query = graphql`
  {
    prismic {
      allModernSlaveryActs {
        edges {
          node {
            page_title
            date
            body_copy
            attachment {
              ... on PRISMIC__FileLink {
                _linkType
                url
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

const ModernSlaveryActData = () => (
  <StaticQuery
    query={`${query}`}
    render={data => <ModernSlaveryActPage data={data} />}
  />
);

export default ModernSlaveryActData;
